<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
      :key="key"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('orders.table.order_reject_reason') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">
          <wameed-dropdown
            v-model="form.status"
            label="حالة الإلغاء"
            :placeholder="$t('orders.table.cancel_reason_placeholder')"
            variant="disable"
            :items="[
                {name: 'ملغي', value: 'canceled'},
                {name: 'مرتجع', value: 'refunded'},
              ]"
            title="name"
            class="wameed_dropdown"
            rules="required"
            @input="onReasonChange"
            :no_options="$t('common.no_options')"

          />
        </b-col>
        <b-col md="12">
          <wameed-dropdown
              v-model="form.reason"
              :label="$t('orders.table.cancel_reason')"
              :placeholder="$t('orders.table.cancel_reason_placeholder')"
              variant="disable"
              :items="[
                {name: 'العميل غير متواجد في المكان'},
                {name: 'العميل طلب تغيير الميعاد'},
                {name: 'العميل لم يرد على الهاتف'},
                {name: 'العميل طلب تأجيل الإستلام'},
                {name: 'العميل رفض الإستلام'},
                {name: 'الهاتف ناقص'},
                {name: 'الهاتف مغلق'},
                {name: 'طلب مرفوض من العميل لصعوبة نقلها'},
              ]"
              title="name"
              class="wameed_dropdown"
              rules="required"
              @input="onReasonChange"
              :no_options="$t('common.no_options')"

          />
        </b-col>
        <b-col md="12">
          <text-area
              id="package-ad"
              v-model="form.note"
              :label="$t('orders.table.cancel_reason_note')"
              :placeholder="$t('form.text.placeholder')"
          />
        </b-col>
        <b-col cols='12' md='4' class="mt-5">
<!--          <b-form-checkbox-group-->
<!--            v-model="selected"-->
<!--            :options="[-->
<!--              { item: '1', name: 'one' },-->
<!--              { item: '2', name: 'two' },-->
<!--            ]"-->
<!--            class="mb-3"-->
<!--            value-field="item"-->
<!--            text-field="name"-->
<!--            disabled-field="notEnabled"-->
<!--          ></b-form-checkbox-group>-->
          <b-form-checkbox v-model="form.show_to_customer" name="show_to_customer">
            إظهار للعميل
          </b-form-checkbox>
          <b-form-checkbox v-model="form.show_to_provider" name="show_to_provider">
            إظهار للجهة
          </b-form-checkbox>
        </b-col>

      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.send')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextArea as TextArea} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions, mapGetters} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextArea,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    order: {
      type: [Number, String],
      default: null,
    },

  },

  data() {
    return {
      key: 0,
      form: {
        status: null,
        note: '',
        reason: null,
        show_to_provider: false,
        show_to_customer: false,
      },
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      // getCancelReasons: 'vendor/orders/getCancelReasons',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
      cancel: "admin/talqahBusiness/orders/cancel",
      update: "vendor/categories/update",
    }),
    onReasonChange() {
      this.key++
    },
    submitOrder() {
      console.log(this.form.show_to_provider, this.form.show_to_customer);
      console.log(this.form.status.value, this.form.note, this.form.reason.name, this.order.id);
      let data = {
        "id": this.order.id,
        "cancel_note": this.form.note,
        "cancel_status": this.form.status.value,
        "cancel_reason": this.form.reason.name,
        "show_to_provider": this.form.show_to_provider,
        "show_to_customer": this.form.show_to_customer
      };

      this.cancel(data).then(() => {
        this.$emit('onComplete', true);
        this.closeModal();
        this.$store.commit('loadingFinish', null, {root: true});
      });
    },
    closeModal() {
      this.show = false;
      this.form.note = '';
      this.form.status = null;
      this.form.reason = null;
    },
  },
};
</script>
