<template>
  <w-tables
      :noDataTitle="$t('table.no_data_title')"
      :noDataSubtitle="$t('table.no_data_subtitle')"
      :items="order"
      :fields="fields"
      :perPage="10000"
      :custimized-items="[
                { name: 'name' },
                { name: 'price' },
                { name: 'total' },
                // { name: 'size' },
                // { name: 'type' },
                // { name: 'addons' },
                // { name: 'dimension' },
                { name: 'quantity' },
              ]"
  >
    <template slot="name" slot-scope="{ data }">
      <div class="order-table_name" :class="editOrder?'cursor-pointer':''" @click="$emit('onCartEdit',data.item)">

        <name-tile
            :name="data.item.name"
            :image="data.item.image"
        />
        <div v-if="editOrder" class="edit">{{ $t('common.edit') }}</div>
      </div>
    </template>


<!--    <template slot="size" slot-scope="{ data }">-->
<!--      <check-value :value="data.item.size"/>-->
<!--    </template>-->

<!--    <template slot="dimension" slot-scope="{ data }">-->
<!--      <div>-->
<!--        <span> {{ $t('common.length') }} : <span class="text-main">{{-->
<!--            data.item.dimension.length-->
<!--          }} </span>{{ $t('common.meter') }} , </span>-->
<!--        <span> {{ $t('common.width') }} : <span class="text-main">{{-->
<!--            data.item.dimension.width-->
<!--          }} </span>{{ $t('common.meter') }} , </span>-->
<!--        <span> {{ $t('common.height') }} : <span class="text-main">{{-->
<!--            data.item.dimension.height-->
<!--          }} </span>{{ $t('common.meter') }} , </span>-->
<!--        <span> {{ $t('common.weight') }} : <span class="text-main">{{-->
<!--            data.item.dimension.weight-->
<!--          }} </span>{{ $t('common.kg') }}</span>-->

<!--      </div>-->
<!--    </template>-->

<!--    <template slot="type" slot-scope="{ data }">-->
<!--      <check-value :value="data.item.type"/>-->
<!--    </template>-->

<!--    <template slot="addons" slot-scope="{ data }">-->
<!--      <check-value :value="data.item.addons"/>-->
<!--    </template>-->


    <template slot="price" slot-scope="{ data }">
      <check-price
          :price="data.item.price"
      />
    </template>

    <template slot="total" slot-scope="{ data }">
      <check-price
          :price="data.item.price * data.item.quantity"
      />
    </template>

    <template slot="quantity" slot-scope="{ data }">
      x{{ data.item.quantity }}
    </template>


  </w-tables>
</template>

<script>


import NameTile from "@/components/nameTile.table";
import CheckPrice from "@/components/checkPrice";
import CheckValue from "@/components/checkValue";
import {WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm';

export default {
  components: {
    CheckValue,
    CheckPrice,
    NameTile,
    WTables,
  },
  props: {
    order: [],
    tableFields: [],
    editOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('products.table.name'),
          sortable: false,
          tdClass: 'w-3/8'
        },

        // {
        //   key: 'size',
        //   label: this.$i18n.t('products.table.sizes'),
        //   sortable: false,
        // },
        //
        //
        // {
        //   key: 'type',
        //   label: this.$i18n.t('products.table.types'),
        //   sortable: false,
        // },
        //
        // {
        //   key: 'service_type',
        //   label: this.$i18n.t('products.table.service_type'),
        //   sortable: false,
        // },
        // {
        //   key: 'medicine_name',
        //   label: this.$i18n.t('products.table.medicine_name'),
        //   sortable: false,
        //   tdClass: 'w-3/8'
        // },
        //
        // {
        //   key: 'shipping_boundary',
        //   label: this.$i18n.t('products.table.shipping_boundary'),
        //   sortable: false,
        // },
        //
        //
        // {
        //   key: 'order_type',
        //   label: this.$i18n.t('products.table.order_type'),
        //   sortable: false,
        // },


        // {
        //   key: 'count',
        //   label: this.$i18n.t('products.table.count'),
        //   sortable: false,
        // },

        // {
        //   key: 'dimension',
        //   label: this.$i18n.t('products.table.dimension'),
        //   sortable: false,
        // },

        // {
        //   key: 'vehicle_type',
        //   label: this.$i18n.t('products.table.vehicle_type'),
        //   sortable: false,
        // },
        // {
        //   key: 'addons',
        //   label: this.$i18n.t('products.table.addons'),
        //   sortable: false,
        // },
        {
          key: 'quantity',
          label: this.$i18n.t('table.quantity'),
          sortable: false,
        },
        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: false,
          tdClass: 'w-120px'
        },

        {
          key: 'total',
          label: this.$i18n.t('table.total'),
          sortable: false,
          tdClass: 'w-120px'
        },

      ],
    };
  },

  computed: {
    computedFields() {
      return this.fields.filter((item) => {
        return this.tableFields.includes(item.key);
      });
    }
  }


}
;
</script>
