<template>
  <div class="order-detail--card mb-5">
    <div class="order-detail-info">


      <div class="flex">
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.no') }}</div>
          <div class="value">#{{ detail.ref_id }}</div>
        </div>
<!--        <div class="tile-text" >-->
<!--          <div class="text">{{ $t('table.vehicle_type') }}</div>-->
<!--          <div class="value">{{ detail.vehicle_type }}</div>-->
<!--        </div>-->
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.date') }}</div>
          <div class="value">{{ formatDate(detail.created_at) }}</div>
        </div>
        <div v-if="detail.completedAt" class="tile-text">
          <div class="text">{{ $t('orders.table.duration') }}</div>
          <div class="value">{{ getTimePassed(detail.assigned_at, detail.completedAt) }}</div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text" v-if="detail.vendorId">
          <div class="text">{{ $t('table.price') }}</div>
          <div class="value">{{ formatNumber(detail.cost) }}</div>
        </div>
        <div class="tile-text" v-else>
          <div class="text">{{ $t('table.delivery_price') }}</div>
          <div class="value">{{ formatNumber(detail.delivery_fee) }}</div>
        </div>
<!--        <div class="tile-text">-->
<!--          <div class="text">{{ $t('orders.payment_type') }}</div>-->
<!--          <div class="value">{{ detail.payment_type || $t('common.not_specified') }}</div>-->
<!--        </div>-->

        <div class="tile-text">
          <div class="text">{{ $t('orders.table.status') }}</div>
          <div class="value">
            <custom-badge :text="getStatusInfo(detail.status).name" :color="getStatusInfo(detail.status).color"/>
          </div>
        </div>
      </div>

      <div class="flex">
<!--        <div class="tile-text">-->
<!--          <div class="text">{{ $t('orders.table.type') }}</div>-->
<!--          <div class="value">-->
<!--            {{ detail.service_type }}-->
<!--          </div>-->
<!--        </div>-->
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.place') }}</div>
          <div class="value">
            <check-value :value="detail.provider_name"/>
          </div>
        </div>
      </div>


      <div class="flex" v-if="detail.payment">
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.payment_method') }}</div>
          <div class="value">
            {{ detail.payment.method }}
          </div>
        </div>
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.payment_ref') }}</div>
          <div class="value">
            <check-value :value="detail.payment.ref"/>
          </div>
        </div>
        <div class="tile-text">
          <div class="text">عدد مرات طباعة الفاتورة</div>
          <div class="value">
            0
          </div>
        </div>
      </div>


    </div>

    <div class="h-divider"></div>
    <div class="d-flex justify-content-between align-items-center flex-wrap" style=" gap: 16px;">

      <div class="order-detail-btns " v-if="showBtns">
        <wameed-btn
            variant="main"
            size="sm"
            :title="addPriceBtnText"
            classes="text-book-18  rounded-12   px-4 main-btn"
            block
            @onClick="$emit('onAddPrices')"
        />
<!--        <wameed-btn-->
<!--            variant="gray"-->
<!--            size="sm"-->
<!--            :title="$t('orders.edit')"-->
<!--            classes="text-book-18  rounded-12   px-4 secondary-btn"-->
<!--            block-->
<!--            @onClick="$emit('onEdit')"-->
<!--        />-->
<!--        <wameed-btn-->
<!--            :disabled="!canAccept"-->
<!--            variant="success"-->
<!--            size="sm"-->
<!--            :title="$t('orders.accept')"-->
<!--            classes="text-book-18  rounded-12   px-4 success-btn"-->
<!--            block-->

<!--            @onClick="$emit('onAccept')"-->
<!--        />-->
<!--        <wameed-btn-->
<!--          variant="warning"-->
<!--          size="sm"-->
<!--          :title="$t('orders.change_payment_type')"-->
<!--          classes="text-book-18  rounded-12   px-4 warning-btn"-->
<!--          block-->

<!--          @onClick="$emit('onPaymentTypeChange')"-->
<!--        />-->
        <wameed-btn
            variant="danger"
            size="sm"
            :title="$t('orders.cancel')"
            classes="text-book-18  rounded-12   px-4"
            block

            @onClick="$emit('onCancel')"
        />
        <wameed-btn
          variant="outline-main"
          size="sm"
          prependIcon="btn-arrow-left-icon"
          :title="$t('status.change')"
          style="max-width: 170px;"
          classes="text-book-18  rounded-12 px-4 outline-main-btn rotate-icon"
          block
          @onClick="$emit('onChangeStatus')"
        />
      </div>
      <div class="order-detail-btns" v-else>
        <wameed-btn
          variant="outline-main"
          size="sm"
          prependIcon="btn-arrow-left-icon"
          :title="$t('status.change')"
          style="max-width: 170px;"
          classes="text-book-18  rounded-12 px-4 outline-main-btn rotate-icon"
          block
          @onClick="$emit('onChangeStatus')"
        />
      </div>

<!--      <div class="order-detail-btns " v-else>-->
<!--        <wameed-btn-->
<!--          variant="warning"-->
<!--          size="sm"-->
<!--          :title="$t('orders.change_payment_type')"-->
<!--          classes="text-book-18  rounded-12   px-4 warning-btn"-->
<!--          block-->

<!--          @onClick="$emit('onPaymentTypeChange')"-->
<!--        />-->
<!--      </div>-->

      <wameed-btn
          variant="main"
          size="sm"
          prependIcon="btn-print-icon"
          title="طباعة فاتورة"
          style="max-width: 170px;"
          classes="text-book-18  rounded-12 px-4 outline-main-btn rotate-icon"
          block
          @onClick="$emit('onPrint')"
      />
    </div>
  </div>
</template>
<script>
import CheckValue from "@/components/checkValue"
import CustomBadge from "@/components/customBadge"
import {serviceTypes} from "@/enums/serviceType.enum";

export default {
  name: 'infoSection',
  components: {CheckValue, CustomBadge},
  props: {
    detail: {},
    edit: {
      type: Boolean,
      default: false
    },
    addPriceBtnText: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      statuses: [
        { name: "قيد المراجعة", value: 'pending', color: 'f0ad4e' }, // Orange
        { name: "جاري الإستلام", value: 'in_recive', color: 'f0ad4e' }, // Orange
        { name: "تم الإستلام بالمخزن", value: 'recieved_in_warehouse', color: '17a2b8' }, // Teal (Info)
        { name: "التأكيد من العميل", value: 'confirmed_by_client', color: 'ffc107' }, // Yellow
        { name: "قيد النقل", value: 'in_transit', color: '007bff' }, // Blue
        { name: "تم استلام الفرع", value: 'recieved_in_branch', color: '6f42c1' }, // Purple
        { name: "الاسناد للموصل", value: 'assigned_to_driver', color: 'fd7e14' }, // Orange-Red
        { name: "جارِ التجهيز", value: 'preparing', color: '20c997' }, // Green-Teal
        { name: "في الطريق إلى التسليم للعميل", value: 'out_for_delivery', color: '28a745' }, // Green
        { name: "مقبول", value: 'approved', color: '5cb85c' }, // Green
        { name: "في الطريق", value: 'in_way', color: '0275d8' }, // Blue
        { name: "ملغي", value: 'canceled', color: 'dc3545' }, // Red
        { name: "معلق", value: 'on_hold', color: 'ffc107' }, // Yellow
        { name: "مرتجع", value: 'refunded', color: '6610f2' }, // Purple
        { name: "تم التوصيل", value: 'completed', color: '6c757d' } // Gray
      ]

    }
  },
  methods: {
    getStatusInfo(statusValue) {
      return this.statuses.find(status => status.value === statusValue) ||
        { name: statusValue, color: 'FAA61A' }
    },
    getTimePassed(createdAt, completedAt) {
      const createdDate = new Date(createdAt);
      const completedDate = new Date(completedAt);

      const differenceInMs = completedDate - createdDate;

      const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor((differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));

      const parts = [];
      if (days) parts.push(this.$tc("time.days", days, { count: days }));
      if (hours) parts.push(this.$tc("time.hours", hours, { count: hours }));
      if (minutes) parts.push(this.$tc("time.minutes", minutes, { count: minutes }));

      return parts.join(` ${this.$t("time.and")} `);
    }
  },
  computed: {
    showBtns() {
      return this.detail.status === 'pending';
    },
    canAccept() {
      if(this.detail.service_type_id === serviceTypes.medicine){
        return  this.detail.order_price != null && this.detail.order_price != 0;
      }else if(this.detail.service_type_id === serviceTypes.shipping) {
        return this.detail.delivery_price != null && this.detail.delivery_price != 0;
      }
      return  true;
    }
  }

}
</script>