var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{key:_vm.key,ref:"form",staticClass:"repeater__form",staticStyle:{"overflow":"visible"},attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"content-class":"package-modal rounded-8","dialog-class":"wameed-modal-md","no-fade":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('template',{slot:"modal-header-close"},[_c('close-icon')],1),_c('template',{slot:"modal-title"},[_c('div',{staticClass:"d-block text-center"},[_c('h3',{staticClass:"text-reg-18"},[_vm._v(" "+_vm._s(_vm.$t('orders.table.order_reject_reason'))+" ")])])]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('wameed-dropdown',{staticClass:"wameed_dropdown",attrs:{"label":"حالة الإلغاء","placeholder":_vm.$t('orders.table.cancel_reason_placeholder'),"variant":"disable","items":[
                {name: 'ملغي', value: 'canceled'},
                {name: 'مرتجع', value: 'refunded'} ],"title":"name","rules":"required","no_options":_vm.$t('common.no_options')},on:{"input":_vm.onReasonChange},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('wameed-dropdown',{staticClass:"wameed_dropdown",attrs:{"label":_vm.$t('orders.table.cancel_reason'),"placeholder":_vm.$t('orders.table.cancel_reason_placeholder'),"variant":"disable","items":[
                {name: 'العميل غير متواجد في المكان'},
                {name: 'العميل طلب تغيير الميعاد'},
                {name: 'العميل لم يرد على الهاتف'},
                {name: 'العميل طلب تأجيل الإستلام'},
                {name: 'العميل رفض الإستلام'},
                {name: 'الهاتف ناقص'},
                {name: 'الهاتف مغلق'},
                {name: 'طلب مرفوض من العميل لصعوبة نقلها'} ],"title":"name","rules":"required","no_options":_vm.$t('common.no_options')},on:{"input":_vm.onReasonChange},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('text-area',{attrs:{"id":"package-ad","label":_vm.$t('orders.table.cancel_reason_note'),"placeholder":_vm.$t('form.text.placeholder')},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"4"}},[_c('b-form-checkbox',{attrs:{"name":"show_to_customer"},model:{value:(_vm.form.show_to_customer),callback:function ($$v) {_vm.$set(_vm.form, "show_to_customer", $$v)},expression:"form.show_to_customer"}},[_vm._v(" إظهار للعميل ")]),_c('b-form-checkbox',{attrs:{"name":"show_to_provider"},model:{value:(_vm.form.show_to_provider),callback:function ($$v) {_vm.$set(_vm.form, "show_to_provider", $$v)},expression:"form.show_to_provider"}},[_vm._v(" إظهار للجهة ")])],1)],1),_c('template',{slot:"modal-footer"},[_c('wameed-btn',{attrs:{"classes":"  text-med-14 text-white mx-0 rounded-8","title":_vm.$t('btn.send'),"disabled":invalid,"type":"submit","variant":"main"},on:{"onClick":function($event){return _vm.submitOrder()}}}),_c('wameed-btn',{attrs:{"classes":"  text-med-14 text-font-secondary mx-0 rounded-8 ","title":_vm.$t('btn.cancel'),"type":"button","variant":"gray"},on:{"onClick":function($event){return _vm.closeModal()}}})],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }